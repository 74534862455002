import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountPageState {
  private readonly state = {
    $subscriptions: signal<AccountSubscriptions | null>(null),
    $pageContent: signal<AccountPage | null>(null),
  };

  public readonly $subscriptions = this.state.$subscriptions.asReadonly();
  public readonly $pageContent = this.state.$pageContent.asReadonly();

  public set subscriptions(subscriptions: AccountSubscriptions) {
    this.state.$subscriptions.set(subscriptions);
  }
  public set pageContent(pageContent: AccountPage) {
    this.state.$pageContent.set(pageContent);
  }
}
